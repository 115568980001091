import React from 'react'
import PropTypes from 'prop-types'
import './SuspenseLoader.css'
import { Box, CircularProgress } from '@mui/material'

const SuspenseLoader = props => {
  return (
    <Box
      width={'100%'}
      height={'90vh'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <CircularProgress />
    </Box>
  )
}

SuspenseLoader.propTypes = {}

export default SuspenseLoader