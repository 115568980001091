import React from 'react'
// ----------------------------------------------------------------------

export default function Link(theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    }
  }
}
