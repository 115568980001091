import React from 'react'
//
import { InputSelectIcon } from './CustomIcons'

// ----------------------------------------------------------------------

export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon
      }
    }
  }
}
